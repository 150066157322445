<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Qualifiche"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Nome: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div 
        :style="{
          'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
          'overflow-y': 'hidden'
        }" 
        ref="dataTableWrapper"
      >
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento qualifiche ..."
          :headers="headers"
          :loading="loading"
          :items="filteredQualifications"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-select="false"
          @edit="handleEdit"
          @delete="handleDelete"
          @dblclick:row="handleEditDoubleClick"
        ></TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import qualificationsService from '@/services/qualifications/qualifications.service.js'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";

export default {
  name: "QualificationsRegistryList",
  components: {
    FullScreenDialog,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter
  },
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      qualifications: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'string', operator: 'like', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined, },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'name', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [],
      isLicenseBlocked: undefined
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchQualifications()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchQualifications() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      qualificationsService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(!!this.totalPages && this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.qualifications = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      qualificationsService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(qualification) {
      this.$router.push({
        name: 'QualificationsRegistryEditForm',
        params: {
          id: qualification.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'QualificationsRegistryList'}).href,
          pathName: "QualificationsRegistryList"
        }
      })
    },
    handleEditDoubleClick(row, {item: qualification}) {
      this.$router.push({
        name: 'QualificationsRegistryEditForm',
        params: {
          id: qualification.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'QualificationsRegistryList'}).href,
          pathName: "QualificationsRegistryList"
        }
      })
    },
    handleDelete(qualification) {
      let confirmed = confirm('Sei sicuro di voler eliminare ' + qualification.name + ' ?')

      if(confirmed) {
        this.loadingItems = true
        qualificationsService.archive(qualification).then(() => {
          this.fetchQualifications()
          this.loadingItems = false
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'QualificationsRegistryNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'QualificationsRegistryList'}).href
        }
      })
    },
    applyFilters() {
      this.fetchQualifications();
    },
  },
  computed: {
    filteredQualifications() {
      return this.qualifications
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchQualifications()
    },
    rowPerPage() {
      this.fetchQualifications()
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>